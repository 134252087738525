import { Controller } from "@hotwired/stimulus"
import { navigator } from "@hotwired/turbo"
import debounce from "lodash/debounce"

export default class extends Controller {


  connect () {
    this.debouncedFetch = debounce(this.fetch, 300)
  }

  update () {
    this.fetch()
  }

  fetch () {
    this.element.requestSubmit()
  }
}

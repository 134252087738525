import { Controller } from "@hotwired/stimulus"
import { navigator } from "@hotwired/turbo"
import debounce from "lodash/debounce"

export default class extends Controller {

  static targets = [ "addToCart" ]

  connect () {
    this.debouncedFetch = debounce(this.fetch, 100)

    this.element.querySelectorAll("input").forEach(el => {
      el.addEventListener('change', () => {
        this.update()
      })
    })
  }

  update () {
    this.debouncedFetch()
  }

  fetch () {
    navigator.submitForm(this.element)
  }

  scrollToAddToCart (e) {
    console.log(isLink(e.target))
    if (isLink(e.target)) {
      return
    }

    this.addToCartTargets.forEach(el => {
      if (isVisible(el)) {
        el.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    })
  }

}

function isLink(element) {
  while (element) {
    if (element.tagName === "A") {
      return true
    }
    element = element.parentNode
  }

  return false
}

function isVisible(element) {
  return element.offsetWidth > 0 || element.offsetHeight > 0
}

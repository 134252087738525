import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle'

export default class extends Controller {
  static targets = [ "buttonNext", "buttonPrev", "pagination" ]

  static values = {
    slides: String,
    spaceBetween: String,
    autoplay: Number,
    rows: Number,
    center: { type: Boolean, default: true },
    rewind: { type: Boolean, default: false },
    effect: { type: String, default: "slide" },
    lazyload: { type: Boolean, default: false },
  }

  connect () {
    this.init()
  }

  init () {
    this.instance = new Swiper(this.container, this.config)
    this.instance.on('slideChange', () => {
      this.slideChange()
    })
  }

  slideChange () {
    const video = this.instance.slides[this.instance.activeIndex].querySelector("video")

    if (video) {
      video.play()
    }
  }

  get container () {
    return this.element
  }

  get config () {
    let base = {
      watchOverflow: true,
      watchSlidesProgress: true,
      centeredSlides: this.centerValue,
      rewind: this.rewindValue,
      effect: this.effectValue,
      preloadImages: !this.lazyloadValue,
      lazy: {
        enabled: this.lazyloadValue,
        loadPrevNext: true
      },
      coverflowEffect: {
        rotate: 10,
        stretch: 0,
        depth: 150,
        modifier: 1,
        slideShadows: false,
      },
      breakpoints: {
        576: {},
        768: {},
        992: {},
        1200: {}
      }
    }

    if (this.hasButtonNextTarget && this.hasButtonPrevTarget) {
      base.navigation = {
        nextEl: this.buttonNextTarget,
        prevEl: this.buttonPrevTarget
      }
    }

    if (this.hasPaginationTarget) {
      base.pagination = {
        el: this.paginationTarget,
        type: "bullets"
      }
    }

    if (this.hasSlidesValue) {
      let breakpoints = breakpointsFor(this.slidesValue)

      base.slidesPerView = breakpoints.xs
      base.breakpoints["576"].slidesPerView = breakpoints.sm
      base.breakpoints["768"].slidesPerView = breakpoints.md
      base.breakpoints["992"].slidesPerView = breakpoints.lg
      base.breakpoints["1200"].slidesPerView = breakpoints.xl
    }

    if (this.hasSpaceBetweenValue) {
      let breakpoints = breakpointsFor(this.spaceBetweenValue)

      base.spaceBetween = parseInt(breakpoints.xs)
      base.breakpoints["576"].spaceBetween = parseInt(breakpoints.sm)
      base.breakpoints["768"].spaceBetween = parseInt(breakpoints.md)
      base.breakpoints["992"].spaceBetween = parseInt(breakpoints.lg)
      base.breakpoints["1200"].spaceBetween = parseInt(breakpoints.xl)
    }

    if (this.hasAutoplayValue) {
      base.autoplay = {
        delay: this.autoplayValue,
        disableOnInteraction: true
      }
    }

    return base
  }

}

function breakpointsFor (input) {
  let array = input.split('/')

  return {
    xs: array[0],
    sm: array[1],
    md: array[2],
    lg: array[3],
    xl: array[4],
  }
}
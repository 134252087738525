import { PageRenderer } from "@hotwired/turbo"

// // copied since `util` is not exported
const nextEventLoopTick = () => new Promise((resolve) => {
  setTimeout(() => resolve(), 0);
});

PageRenderer.prototype.assignNewBody = async function assignNewBody() {
  await nextEventLoopTick();
  const body = document.querySelector("[data-turbo-drive-body]")
  const el   = this.newElement.querySelector("[data-turbo-drive-body]")

  if (body && el) {
    body.replaceWith(el)
  } else if (document.body && this.newElement instanceof HTMLBodyElement) {
    document.body.replaceWith(this.newElement)
  } else {
    document.documentElement.appendChild(this.newElement)
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    window.persistScroll ||= {}

    if (window.persistScroll[this.element.id]) {
      this.element.scrollTop = window.persistScroll[this.element.id][0]
      this.element.scrollLeft = window.persistScroll[this.element.id][1]
    }

    this.element.addEventListener("scroll", () => {
      window.persistScroll[this.element.id] = [this.element.scrollTop, this.element.scrollLeft]
    })
  }
}

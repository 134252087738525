import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {

  kill (e) {
    this.close(e)
    this.containerTarget.remove()
  }

}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="welcome-campaign"
export default class extends Controller {

  static targets = [ "modal" ]

  connect() {
    if (!this.hasAppeared) {
      setTimeout(() => {
        this.appear()
      }, 1000)
    }
  }

  appear () {
    document.cookie = "welcome_campaign_appeared=true"
    this.modalTarget.classList.remove("hidden")
  }

  disappear () {
    this.modalTarget.classList.add("hidden")
  }

  get hasAppeared () {
    return document.cookie.includes("welcome_campaign_appeared=true")
  }
}

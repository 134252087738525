import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect () {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.element)
    }
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["player", "overlay"]
  
  static values = {
    src: String,
    muted: {
      type: Boolean,
      default: false
    }
  }

  connect () {
    if (typeof window.youTubeIframeAPIReady === 'undefined') {
      this.loadApi()
    }

    this.loadPlayer()
  }

  loadPlayer () {
    const loader = setInterval(() => {
      if (window.youTubeIframeAPIReady) {
        this.createPlayer()
        clearInterval(loader)
      }
    }, 10)
  }

  loadApi () {
    window.youTubeIframeAPIReady = false
    // YT api calls this function
    window.onYouTubeIframeAPIReady = function () {
      window.youTubeIframeAPIReady = true
    }

    var tag = document.createElement('script')
    tag.src = "https://www.youtube.com/iframe_api"
    var firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
  }

  createPlayer () {
    this.player = new YT.Player(this.playerTarget, {
      videoId: this.srcValue,
      playerVars: {
        'playsinline': 1,
        'cc_load_policy': 1,
        'autoplay': 0
      }
    })
    this.player.addEventListener("onApiChange", () => {
      this.player.loadModule("captions")
      this.player.setOption("captions", 'fontSize', 3)
    })

    this.player.getIframe().removeAttribute("width")
    this.player.getIframe().removeAttribute("height")
  }

  play () {
    if (this.hasOverlayTarget) {
      this.overlayTarget.classList.add('opacity-0')
      this.overlayTarget.classList.add('pointer-events-none')
    }

    if (this.mutedValue) {
      this.player.mute()
    }
    
    this.player.playVideo()
  }

  stop () {
    if (this.hasOverlayTarget) {
      this.overlayTarget.classList.remove('opacity-0')
      this.overlayTarget.classList.remove('pointer-events-none')
    }
    this.player.stopVideo()
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element", "reference"]

  static values = {
    position: { type: String, default: "top" }
  }

  classes = {
    top: {
      show: "animate__fadeIn",
      hide: "animate__fadeOu"
    },
    bottom: {
      show: "animate__fadeIn",
      hide: "animate__fadeOu"
    }
  }

  connect () {
    let observer = new IntersectionObserver(entries => {
      // isIntersecting is true when element and viewport are overlapping
      // isIntersecting is false when element and viewport don't overlap
      if(entries[0].isIntersecting === true) {
        this.hide()
      }
      else if (this.positionValue === "top" && entries[0].boundingClientRect.top < 0) {
        this.show()
      }
      else if (this.positionValue === "bottom" && entries[0].boundingClientRect.top > 0) {
        this.show()
      }
        
    }, { threshold: [0] })

    observer.observe(this.referenceTarget)
  }

  hide () {
    this.elementTarget.classList.add(this.classes[this.positionValue].hide)
    this.elementTarget.classList.remove(this.classes[this.positionValue].show)
  }

  show () {
    this.elementTarget.classList.remove(this.classes[this.positionValue].hide)
    this.elementTarget.classList.add(this.classes[this.positionValue].show)
  }

}



import { Controller } from "@hotwired/stimulus"
import { navigator } from "@hotwired/turbo"

export default class extends Controller {

  connect () {
    this.element.requestSubmit()
  }

}

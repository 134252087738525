import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect () {
    const event = new Event("connected")
    this.element.dispatchEvent(event)
  }

}

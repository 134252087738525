import { Controller } from "@hotwired/stimulus"
import mapboxgl from 'mapbox-gl'

export default class extends Controller {

  static values = {
    markers: Array,
    zoom: { type: Number, default: 6 },
    center: { type: Array, default: [10.5, 56.3] },
    interactive: { type: Boolean, default: true },
    locateUser: { Type: Boolean, default: false }
  }

  connect() {
    mapboxgl.accessToken = 'pk.eyJ1Ijoibm91cGV0aXQiLCJhIjoiY2w1cDRoN3g4MWsxbTNqbXB2Zm9lMzk0MSJ9.ByQOmWJqRErOCr02YRP0VQ';


    const map = new mapboxgl.Map({
      container: this.element,
      style: 'mapbox://styles/mapbox/light-v10',
      center: this.centerValue,
      zoom: this.zoomValue,
      cooperativeGestures: this.interactiveValue,
      interactive: this.interactiveValue
    })

    if (this.interactiveValue) {
      map.addControl(new mapboxgl.NavigationControl())
      map.addControl(new mapboxgl.FullscreenControl())
    }
    if (this.interactiveValue && this.locateUserValue) {
      map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        }
      }))
    }

    this.markersValue.forEach(data => {
      const marker = new mapboxgl.Marker({ color: '#686258' })
      marker.setLngLat([data.longitude, data.latitude])

      if (data.preview_url) {
        const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(`<turbo-frame id="preview" src="${data.preview_url}" loading="lazy"></turbo-frame>`)
        marker.setPopup(popup)
      }

      marker.addTo(map)
    })


  }
}

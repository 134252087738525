import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.play()
    this.element.addEventListener('playing', () => {
      this.fadeIn()
    })
  }
  fadeIn () {
    this.element.classList.remove('opacity-0')
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="switch"
export default class extends Controller {
  static targets = ["content"]
  toggle (e) {
    e.currentTarget.classList.add("hidden")
    this.contentTarget.classList.remove("hidden")
    let input = this.contentTarget.querySelector("input, textarea")
    if (input) {
      input.focus()
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["video", "iconClosed", "iconOpened"]

  connect () {
    this.state = "closed"
  }

  toggle () {
    if (this.state === "closed") {
      this.open()
      this.state = "opened"
    }
    else {
      this.close()
      this.state = "closed"
    }
  }

  handleOpened (e) {
    if(this !== e.detail.controller && this.state === "opened") {
      this.close()
    }
  }

  open () {
    this.element.classList.add("bg-brown-100")
    
    this.videoTarget.classList.remove("hidden")
    this.videoTarget.classList.remove("opacity-0")
    this.videoTarget.classList.add("animate__animated")
    this.videoTarget.classList.add("animate__fadeInDown")
    this.videoTarget.classList.add("animate__faster")

    this.iconClosedTarget.classList.add("hidden")
    this.iconOpenedTarget.classList.remove("hidden")
    
    const globalEvent = new CustomEvent("faq:opened", { detail: { controller: this } })
    window.dispatchEvent(globalEvent)

    const event = new Event("opened")
    this.videoTarget.dispatchEvent(event)
  }

  close () {
    this.element.classList.remove("bg-brown-100")

    this.videoTarget.classList.add("hidden")
    this.videoTarget.classList.add("opacity-0")
    this.videoTarget.classList.remove("animate__animated")
    this.videoTarget.classList.remove("animate__fadeInDown")
    this.videoTarget.classList.remove("animate__faster")

    this.iconClosedTarget.classList.remove("hidden")
    this.iconOpenedTarget.classList.add("hidden")

    const event = new Event("closed")
    this.videoTarget.dispatchEvent(event)    
  }
}

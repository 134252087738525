// Entry point for the build script in your package.json

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo


// import Rails from '@rails/ujs'
// Rails.start()
// import "trix"
// import "@rails/actiontext"

import "./turbo_body_renderer"

// import ahoy from "ahoy.js"

import "chartkick/chart.js"
import "./controllers"

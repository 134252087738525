import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect () {
    this.appeared = false

    let observer = new IntersectionObserver(entries => {
      // isIntersecting is true when element and viewport are overlapping
      // isIntersecting is false when element and viewport don't overlap
      if(entries[0].isIntersecting === true)
        this.appear()
        
    }, { threshold: [0] })

    observer.observe(this.element)
  }

  appear () {
    if (this.appeared) {
      return
    }

    const event = new Event("appeared")
    this.element.dispatchEvent(event)

    this.appeared = true


    this.classes.forEach(className => {
      this.element.classList.add(className)
    })

    if (this.resetAfter) {
      setTimeout(() => {
        this.classes.forEach(className => {
          this.element.classList.remove(className)
        })
      }, this.resetAfter)
    }
  }


  get classes () {
    return this.element.dataset.appearingClasses.split(' ')
  }

  get resetAfter () {
    return this.element.dataset.appearingResetAfter
  }


}


